import { Row, Col, size, Container } from '@everlywell/leaves';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useSpring, config } from 'react-spring';

import * as S from './WhyTrustUsSection.styles';

const ICON_SIZE = 56; // px

export type WhyTrustUsSectionProps = {
  /** List of content to be displayed */
  listOfContent: Array<{
    heading: string; //something
    description?: string;
    subheading?: string;
    image: IGatsbyImageData;
    imageAlt: string;
    cta?: { text: string; url: string };
  }>;
};

/**
 * This component is used to display a list of content, with title and description, with an image.
 * To use this section, configure the Page Section entry on the PDP page to have
 * the **Component Name** field set to **WhyTrustUsSection**.
 *
 * See storybook for visual examples.
 */
function WhyTrustUsSection({
  listOfContent,
}: WhyTrustUsSectionProps): JSX.Element {
  const { ref: contentCardsEl, inView } = useInView({
    triggerOnce: true,
  });

  const cardsTransition = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : `translateY(${size.xl3}px)`,
    config: config.slow,
  });

  return (
    <S.Section data-testid="whyTrustUsSection">
      <Container>
        <Row center="xs" around="lg">
          <Col xs={12} lg={12}>
            <S.InfoBlockContainer ref={contentCardsEl}>
              {listOfContent.length
                ? listOfContent.map((content, index) => (
                    <S.AnimatedBlockContainer
                      key={index}
                      style={cardsTransition}
                    >
                      <S.InfoBlock>
                        <S.ImageWrapper size={ICON_SIZE}>
                          <GatsbyImage
                            image={content.image}
                            alt={content.imageAlt}
                            style={{ width: ICON_SIZE, height: ICON_SIZE }}
                          />
                        </S.ImageWrapper>
                        <S.InfoBlockContent>
                          <S.Title>{content.heading}</S.Title>
                          <p>
                            {content.subheading ??
                              (content.description && (
                                <Markdown>{content.description}</Markdown>
                              ))}
                          </p>
                        </S.InfoBlockContent>
                      </S.InfoBlock>
                    </S.AnimatedBlockContainer>
                  ))
                : null}
            </S.InfoBlockContainer>
          </Col>
        </Row>
      </Container>
    </S.Section>
  );
}

export default WhyTrustUsSection;
