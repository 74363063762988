import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import WhyTrustUsSection from '../../../components/PDP/WhyTrustUsSection';

type ContentSection = {
  headline: string;
  subHeadline: string;
  image: IGatsbyImageData;
  cta: { text: string; url: string };
};

export interface WhyTrustUsContainerProps {
  content: {
    listOfContentSections: ContentSection[];
  };
}

export const WhyTrustUsContainer: React.FC<WhyTrustUsContainerProps> = ({
  content,
}) => {
  if (!content) {
    return null;
  }
  const { listOfContentSections } = content;

  if (!listOfContentSections) return null;

  const listOfContent = listOfContentSections.map((content: any) => ({
    heading: content.headline,
    subheading: content.subHeadline,
    description: content.description?.description,
    image: content.image.gatsbyImageData,
    imageAlt: content.image?.description,
    cta: content.cta,
  }));

  return <WhyTrustUsSection listOfContent={listOfContent} />;
};

export default WhyTrustUsContainer;
