import { mediaQueries, size, typography } from '@everlywell/leaves';
import SectionWrapper from 'components/SectionWrapper';
import { animated } from 'react-spring';
import styled from 'styled-components';

export const Section = styled(SectionWrapper)`
  margin-top: ${size.xl1}px;
  ${mediaQueries.forTabletHorizontalUp} {
    margin-top: 0;
    padding-top: ${size.xl1}px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  text-align: left;
  margin-bottom: ${size.xl1}px;
`;

export const ImageWrapper = styled.div<{ size: number }>`
  max-width: ${(props) => props.size}px;
  width: 100%;
  margin-right: ${size.md}px;
`;
export const InfoBlockContent = styled.div`
  p {
    ${typography.bodyTextSmall}
  }

  small {
    font-size: 0.875rem;
  }
`;
export const Title = styled.h3`
  ${typography.subtitle}
  margin-bottom: ${size.xs2}px;
`;

export const AnimatedBlockContainer = styled(animated.div)`
  div[class*='InfoBlock-'] {
    div:first-child {
      background-color: transparent;
    }
  }

  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0 0 33%;
    &:not(:last-child) {
      padding-right: ${size.lg}px;
    }
  }
`;

export const InfoBlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
  ${AnimatedBlockContainer} {
    &:last-child {
      ${InfoBlock} {
        margin-bottom: 0;
      }
    }
  }
`;
